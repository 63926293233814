/* tslint:disable */
/* eslint-disable */
/**
 * Certblock IAM and API Service
 * Identity & access management and API Service for Certblock
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tobias.seidler@siemens.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CertificateCreatedProperties
 */
export interface CertificateCreatedProperties {
    /**
     * UNIX timestamp
     * @type {number}
     * @memberof CertificateCreatedProperties
     */
    'timeOnboarding': number;
    /**
     * 
     * @type {string}
     * @memberof CertificateCreatedProperties
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateCreatedProperties
     */
    'certificateUID': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateCreatedProperties
     */
    'deliveryNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CertificateCreatedProperties
     */
    'versionNumber': number;
}
/**
 * 
 * @export
 * @interface CertificateCreator
 */
export interface CertificateCreator {
    /**
     * Generic identifier
     * @type {string}
     * @memberof CertificateCreator
     */
    'id': string;
    /**
     * Display name
     * @type {string}
     * @memberof CertificateCreator
     */
    'name': string;
    /**
     * Certblock user email
     * @type {string}
     * @memberof CertificateCreator
     */
    'email': string;
    /**
     * City
     * @type {string}
     * @memberof CertificateCreator
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof CertificateCreator
     */
    'country': string;
    /**
     * Zip
     * @type {string}
     * @memberof CertificateCreator
     */
    'zip': string;
    /**
     * Street
     * @type {string}
     * @memberof CertificateCreator
     */
    'street': string;
    /**
     * Extra information
     * @type {string}
     * @memberof CertificateCreator
     */
    'extra'?: string;
}
/**
 * 
 * @export
 * @interface CertificateProperties
 */
export interface CertificateProperties {
    /**
     * 
     * @type {number}
     * @memberof CertificateProperties
     */
    'consigneeIndex': number;
    /**
     * 
     * @type {CertificateCreator}
     * @memberof CertificateProperties
     */
    'creator': CertificateCreator;
    /**
     * 
     * @type {CertificateSupplier}
     * @memberof CertificateProperties
     */
    'supplier': CertificateSupplier;
    /**
     * 
     * @type {Consignee}
     * @memberof CertificateProperties
     */
    'consignee': Consignee;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'deliveryNumber': string;
    /**
     * 
     * @type {Array<MaterialProperties>}
     * @memberof CertificateProperties
     */
    'materialList': Array<MaterialProperties>;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'testResultsText': string;
    /**
     * 
     * @type {boolean}
     * @memberof CertificateProperties
     */
    'checkTestResults': boolean;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'confirmationText': string;
    /**
     * 
     * @type {boolean}
     * @memberof CertificateProperties
     */
    'checkConfirmation': boolean;
    /**
     * UNIX timestamp
     * @type {number}
     * @memberof CertificateProperties
     */
    'inspectionDate': number;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'nameInspector': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'changedCommentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'certificateUid': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateProperties
     */
    'certificateId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CertificateProperties
     */
    'currentVersion': number;
    /**
     * 
     * @type {number}
     * @memberof CertificateProperties
     */
    'numberOfVersions': number;
}
/**
 * 
 * @export
 * @interface CertificateSearchProperties
 */
export interface CertificateSearchProperties {
    /**
     * 
     * @type {string}
     * @memberof CertificateSearchProperties
     */
    'certificateId': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateSearchProperties
     */
    'orderNumber': string;
    /**
     * 
     * @type {Array<MaterialPublicProperties>}
     * @memberof CertificateSearchProperties
     */
    'materialPublicPropertiesList': Array<MaterialPublicProperties>;
    /**
     * 
     * @type {string}
     * @memberof CertificateSearchProperties
     */
    'deliveryNumber'?: string;
    /**
     * UNIX timestamp
     * @type {number}
     * @memberof CertificateSearchProperties
     */
    'inspectionDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CertificateSearchProperties
     */
    'certificateVersion': number;
}
/**
 * 
 * @export
 * @interface CertificateSupplier
 */
export interface CertificateSupplier {
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof CertificateSupplier
     */
    'id': string;
    /**
     * Display name
     * @type {string}
     * @memberof CertificateSupplier
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CompanyAddress
 */
export interface CompanyAddress {
    /**
     * Display name
     * @type {string}
     * @memberof CompanyAddress
     */
    'name': string;
    /**
     * Extra information
     * @type {string}
     * @memberof CompanyAddress
     */
    'extra'?: string;
    /**
     * Street
     * @type {string}
     * @memberof CompanyAddress
     */
    'street': string;
    /**
     * Zip
     * @type {string}
     * @memberof CompanyAddress
     */
    'zip': string;
    /**
     * City
     * @type {string}
     * @memberof CompanyAddress
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof CompanyAddress
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface Consignee
 */
export interface Consignee {
    /**
     * Display name
     * @type {string}
     * @memberof Consignee
     */
    'name': string;
    /**
     * Extra information
     * @type {string}
     * @memberof Consignee
     */
    'extra'?: string;
    /**
     * City
     * @type {string}
     * @memberof Consignee
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof Consignee
     */
    'country': string;
    /**
     * Zip
     * @type {string}
     * @memberof Consignee
     */
    'zip': string;
    /**
     * Street
     * @type {string}
     * @memberof Consignee
     */
    'street': string;
}
/**
 * 
 * @export
 * @interface CreateCertificateProperties
 */
export interface CreateCertificateProperties {
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'supplierId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCertificateProperties
     */
    'consigneeIndex': number;
    /**
     * 
     * @type {Consignee}
     * @memberof CreateCertificateProperties
     */
    'consignee': Consignee;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'deliveryNumber': string;
    /**
     * 
     * @type {Array<MaterialProperties>}
     * @memberof CreateCertificateProperties
     */
    'materialList': Array<MaterialProperties>;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'testResultsText': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCertificateProperties
     */
    'checkTestResults': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'confirmationText': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCertificateProperties
     */
    'checkConfirmation': boolean;
    /**
     * UNIX timestamp
     * @type {number}
     * @memberof CreateCertificateProperties
     */
    'inspectionDate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'nameInspector': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCertificateProperties
     */
    'changedCommentary'?: string;
}
/**
 * Create supplier
 * @export
 * @interface CreateSupplierRequest
 */
export interface CreateSupplierRequest {
    /**
     * Display name
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'name': string;
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface MaterialProperties
 */
export interface MaterialProperties {
    /**
     * 
     * @type {string}
     * @memberof MaterialProperties
     */
    'orderPosition': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialProperties
     */
    'materialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialProperties
     */
    'materialDescription': string;
    /**
     * 
     * @type {number}
     * @memberof MaterialProperties
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialProperties
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialProperties
     */
    'overviewTests': string;
}
/**
 * 
 * @export
 * @interface MaterialPublicProperties
 */
export interface MaterialPublicProperties {
    /**
     * 
     * @type {string}
     * @memberof MaterialPublicProperties
     */
    'orderPosition': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPublicProperties
     */
    'materialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPublicProperties
     */
    'materialDescription': string;
}
/**
 * Generic error
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error message
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface OnboardingProperties
 */
export interface OnboardingProperties {
    /**
     * UNIX timestamp
     * @type {number}
     * @memberof OnboardingProperties
     */
    'timeOnboarding': number;
    /**
     * Display name
     * @type {string}
     * @memberof OnboardingProperties
     */
    'supplier'?: string;
    /**
     * Generic identifier
     * @type {string}
     * @memberof OnboardingProperties
     */
    'userId'?: string;
}
/**
 * Extended information for a user to onboard
 * @export
 * @interface OnboardingRequest
 */
export interface OnboardingRequest {
    /**
     * Display name
     * @type {string}
     * @memberof OnboardingRequest
     */
    'name': string;
    /**
     * City
     * @type {string}
     * @memberof OnboardingRequest
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof OnboardingRequest
     */
    'country': string;
    /**
     * Zip
     * @type {string}
     * @memberof OnboardingRequest
     */
    'zip': string;
    /**
     * Street
     * @type {string}
     * @memberof OnboardingRequest
     */
    'street': string;
    /**
     * List of Tester Names
     * @type {Array<string>}
     * @memberof OnboardingRequest
     */
    'testers'?: Array<string>;
    /**
     * Extra information
     * @type {string}
     * @memberof OnboardingRequest
     */
    'extra'?: string;
    /**
     * List of Material Tests
     * @type {Array<string>}
     * @memberof OnboardingRequest
     */
    'materialTests'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof Supplier
     */
    'id': string;
    /**
     * Display name
     * @type {string}
     * @memberof Supplier
     */
    'name': string;
}
/**
 * User update payload
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * Display name
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'name': string;
    /**
     * City
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'country': string;
    /**
     * Zip
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'zip': string;
    /**
     * Street
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'street': string;
    /**
     * List of Tester Names
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'testers'?: Array<string>;
    /**
     * Extra information
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'extra'?: string;
    /**
     * List of Material Tests
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'materialTests'?: Array<string>;
}
/**
 * User update role payload
 * @export
 * @interface UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
    /**
     * Certblock user role
     * @type {string}
     * @memberof UpdateUserRoleRequest
     */
    'role': string;
}
/**
 * User update supplier payload
 * @export
 * @interface UpdateUserSupplierRequest
 */
export interface UpdateUserSupplierRequest {
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof UpdateUserSupplierRequest
     */
    'supplierId': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Generic identifier
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * Display name
     * @type {string}
     * @memberof User
     */
    'authenticatedName': string;
    /**
     * Display name
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * Certblock user email
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Certblock user role
     * @type {string}
     * @memberof User
     */
    'role': string;
    /**
     * User is approved
     * @type {boolean}
     * @memberof User
     */
    'approved': boolean;
    /**
     * Supplier Identifier
     * @type {string}
     * @memberof User
     */
    'supplier'?: string;
    /**
     * City
     * @type {string}
     * @memberof User
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof User
     */
    'country': string;
    /**
     * Zip
     * @type {string}
     * @memberof User
     */
    'zip': string;
    /**
     * Street
     * @type {string}
     * @memberof User
     */
    'street': string;
    /**
     * List of Tester Names
     * @type {Array<string>}
     * @memberof User
     */
    'testers'?: Array<string>;
    /**
     * Extra information
     * @type {string}
     * @memberof User
     */
    'extra'?: string;
    /**
     * List of Material Tests
     * @type {Array<string>}
     * @memberof User
     */
    'materialTests'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedDate': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'onboardingDate': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'approvalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastActivityDate'?: string;
}

/**
 * CertificateApi - axios parameter creator
 * @export
 */
export const CertificateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a certificate
         * @param {CreateCertificateProperties} createCertificateProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificateCreate: async (createCertificateProperties: CreateCertificateProperties, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCertificateProperties' is not null or undefined
            assertParamExists('certificateCreate', 'createCertificateProperties', createCertificateProperties)
            const localVarPath = `/certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCertificateProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return certificate id based on order info
         * @param {string} orderNumber 
         * @param {string} orderPosition 
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateId: async (orderNumber: string, orderPosition: string, deliveryNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('getCertificateId', 'orderNumber', orderNumber)
            // verify required parameter 'orderPosition' is not null or undefined
            assertParamExists('getCertificateId', 'orderPosition', orderPosition)
            // verify required parameter 'deliveryNumber' is not null or undefined
            assertParamExists('getCertificateId', 'deliveryNumber', deliveryNumber)
            const localVarPath = `/certificate/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = orderNumber;
            }

            if (orderPosition !== undefined) {
                localVarQueryParameter['orderPosition'] = orderPosition;
            }

            if (deliveryNumber !== undefined) {
                localVarQueryParameter['deliveryNumber'] = deliveryNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of associated search properties with this order ID
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateIdList: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('getCertificateIdList', 'orderNumber', orderNumber)
            const localVarPath = `/certificate/idList/{orderNumber}`
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return certificate based on certificate id and version
         * @param {string} id 
         * @param {number} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateSiemensById: async (id: string, version: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCertificateSiemensById', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getCertificateSiemensById', 'version', version)
            const localVarPath = `/certificate/siemens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return certificate version based on certificate id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateVersion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCertificateVersion', 'id', id)
            const localVarPath = `/certificate/version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateApi - functional programming interface
 * @export
 */
export const CertificateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a certificate
         * @param {CreateCertificateProperties} createCertificateProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certificateCreate(createCertificateProperties: CreateCertificateProperties, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certificateCreate(createCertificateProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return certificate id based on order info
         * @param {string} orderNumber 
         * @param {string} orderPosition 
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateId(orderNumber: string, orderPosition: string, deliveryNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateId(orderNumber, orderPosition, deliveryNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of associated search properties with this order ID
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateIdList(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateSearchProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateIdList(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return certificate based on certificate id and version
         * @param {string} id 
         * @param {number} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateSiemensById(id: string, version: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateSiemensById(id, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return certificate version based on certificate id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateVersion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificateApi - factory interface
 * @export
 */
export const CertificateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a certificate
         * @param {CreateCertificateProperties} createCertificateProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificateCreate(createCertificateProperties: CreateCertificateProperties, options?: any): AxiosPromise<CertificateProperties> {
            return localVarFp.certificateCreate(createCertificateProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return certificate id based on order info
         * @param {string} orderNumber 
         * @param {string} orderPosition 
         * @param {string} deliveryNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateId(orderNumber: string, orderPosition: string, deliveryNumber: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCertificateId(orderNumber, orderPosition, deliveryNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of associated search properties with this order ID
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateIdList(orderNumber: string, options?: any): AxiosPromise<Array<CertificateSearchProperties>> {
            return localVarFp.getCertificateIdList(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return certificate based on certificate id and version
         * @param {string} id 
         * @param {number} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateSiemensById(id: string, version: number, options?: any): AxiosPromise<CertificateProperties> {
            return localVarFp.getCertificateSiemensById(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return certificate version based on certificate id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateVersion(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.getCertificateVersion(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificateApi - object-oriented interface
 * @export
 * @class CertificateApi
 * @extends {BaseAPI}
 */
export class CertificateApi extends BaseAPI {
    /**
     * 
     * @summary Create a certificate
     * @param {CreateCertificateProperties} createCertificateProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public certificateCreate(createCertificateProperties: CreateCertificateProperties, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).certificateCreate(createCertificateProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return certificate id based on order info
     * @param {string} orderNumber 
     * @param {string} orderPosition 
     * @param {string} deliveryNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public getCertificateId(orderNumber: string, orderPosition: string, deliveryNumber: string, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).getCertificateId(orderNumber, orderPosition, deliveryNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of associated search properties with this order ID
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public getCertificateIdList(orderNumber: string, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).getCertificateIdList(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return certificate based on certificate id and version
     * @param {string} id 
     * @param {number} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public getCertificateSiemensById(id: string, version: number, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).getCertificateSiemensById(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return certificate version based on certificate id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public getCertificateVersion(id: string, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).getCertificateVersion(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IAMApi - axios parameter creator
 * @export
 */
export const IAMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userApprove: async (gid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userApprove', 'gid', gid)
            const localVarPath = `/user/{gid}/approve`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about the current user.
         * @summary Get information about the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCurrent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDecline: async (gid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userDecline', 'gid', gid)
            const localVarPath = `/user/{gid}/decline`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about the user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (gid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userGet', 'gid', gid)
            const localVarPath = `/user/{gid}`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Method is called by a new user to onboard
         * @summary New user onboarding
         * @param {OnboardingRequest} onboardingRequest Onboarding Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOnboard: async (onboardingRequest: OnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onboardingRequest' is not null or undefined
            assertParamExists('userOnboard', 'onboardingRequest', onboardingRequest)
            const localVarPath = `/user/onboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user. Can be done by an administrator or by the user him/herself
         * @param {string} gid 
         * @param {UpdateUserRequest} updateUserRequest Update User Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (gid: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userUpdate', 'gid', gid)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('userUpdate', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/user/{gid}`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user role. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest Update User Role Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateRole: async (gid: string, updateUserRoleRequest: UpdateUserRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userUpdateRole', 'gid', gid)
            // verify required parameter 'updateUserRoleRequest' is not null or undefined
            assertParamExists('userUpdateRole', 'updateUserRoleRequest', updateUserRoleRequest)
            const localVarPath = `/user/{gid}/role`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user supplier. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserSupplierRequest} updateUserSupplierRequest Update User Supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateSupplier: async (gid: string, updateUserSupplierRequest: UpdateUserSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gid' is not null or undefined
            assertParamExists('userUpdateSupplier', 'gid', gid)
            // verify required parameter 'updateUserSupplierRequest' is not null or undefined
            assertParamExists('userUpdateSupplier', 'updateUserSupplierRequest', updateUserSupplierRequest)
            const localVarPath = `/user/{gid}/supplier`
                .replace(`{${"gid"}}`, encodeURIComponent(String(gid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get approved users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersApproved: async (role?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/approved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (role?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPending: async (role?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IAMApi - functional programming interface
 * @export
 */
export const IAMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IAMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userApprove(gid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userApprove(gid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about the current user.
         * @summary Get information about the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCurrent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCurrent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Decline pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDecline(gid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDecline(gid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information about the user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(gid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(gid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Method is called by a new user to onboard
         * @summary New user onboarding
         * @param {OnboardingRequest} onboardingRequest Onboarding Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOnboard(onboardingRequest: OnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOnboard(onboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user. Can be done by an administrator or by the user him/herself
         * @param {string} gid 
         * @param {UpdateUserRequest} updateUserRequest Update User Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(gid: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(gid, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user role. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest Update User Role Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateRole(gid: string, updateUserRoleRequest: UpdateUserRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateRole(gid, updateUserRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user supplier. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserSupplierRequest} updateUserSupplierRequest Update User Supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateSupplier(gid: string, updateUserSupplierRequest: UpdateUserSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateSupplier(gid, updateUserSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get approved users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersApproved(role?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersApproved(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(role?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pending users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPending(role?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPending(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IAMApi - factory interface
 * @export
 */
export const IAMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IAMApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userApprove(gid: string, options?: any): AxiosPromise<User> {
            return localVarFp.userApprove(gid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about the current user.
         * @summary Get information about the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCurrent(options?: any): AxiosPromise<User> {
            return localVarFp.userCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline pending user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDecline(gid: string, options?: any): AxiosPromise<void> {
            return localVarFp.userDecline(gid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about the user. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(gid: string, options?: any): AxiosPromise<User> {
            return localVarFp.userGet(gid, options).then((request) => request(axios, basePath));
        },
        /**
         * Method is called by a new user to onboard
         * @summary New user onboarding
         * @param {OnboardingRequest} onboardingRequest Onboarding Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOnboard(onboardingRequest: OnboardingRequest, options?: any): AxiosPromise<User> {
            return localVarFp.userOnboard(onboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user. Can be done by an administrator or by the user him/herself
         * @param {string} gid 
         * @param {UpdateUserRequest} updateUserRequest Update User Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(gid: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.userUpdate(gid, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user role. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest Update User Role Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateRole(gid: string, updateUserRoleRequest: UpdateUserRoleRequest, options?: any): AxiosPromise<User> {
            return localVarFp.userUpdateRole(gid, updateUserRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user supplier. Requires \'Siemens Admin\' role.
         * @param {string} gid 
         * @param {UpdateUserSupplierRequest} updateUserSupplierRequest Update User Supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateSupplier(gid: string, updateUserSupplierRequest: UpdateUserSupplierRequest, options?: any): AxiosPromise<User> {
            return localVarFp.userUpdateSupplier(gid, updateUserSupplierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get approved users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersApproved(role?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersApproved(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(role?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersGet(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending users. Requires \'Siemens Admin\' role.
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPending(role?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersPending(role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IAMApi - object-oriented interface
 * @export
 * @class IAMApi
 * @extends {BaseAPI}
 */
export class IAMApi extends BaseAPI {
    /**
     * 
     * @summary Approve pending user. Requires \'Siemens Admin\' role.
     * @param {string} gid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userApprove(gid: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userApprove(gid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about the current user.
     * @summary Get information about the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userCurrent(options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userCurrent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline pending user. Requires \'Siemens Admin\' role.
     * @param {string} gid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userDecline(gid: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userDecline(gid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about the user. Requires \'Siemens Admin\' role.
     * @param {string} gid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userGet(gid: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userGet(gid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Method is called by a new user to onboard
     * @summary New user onboarding
     * @param {OnboardingRequest} onboardingRequest Onboarding Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userOnboard(onboardingRequest: OnboardingRequest, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userOnboard(onboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user. Can be done by an administrator or by the user him/herself
     * @param {string} gid 
     * @param {UpdateUserRequest} updateUserRequest Update User Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userUpdate(gid: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userUpdate(gid, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user role. Requires \'Siemens Admin\' role.
     * @param {string} gid 
     * @param {UpdateUserRoleRequest} updateUserRoleRequest Update User Role Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userUpdateRole(gid: string, updateUserRoleRequest: UpdateUserRoleRequest, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userUpdateRole(gid, updateUserRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user supplier. Requires \'Siemens Admin\' role.
     * @param {string} gid 
     * @param {UpdateUserSupplierRequest} updateUserSupplierRequest Update User Supplier Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public userUpdateSupplier(gid: string, updateUserSupplierRequest: UpdateUserSupplierRequest, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).userUpdateSupplier(gid, updateUserSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get approved users. Requires \'Siemens Admin\' role.
     * @param {string} [role] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public usersApproved(role?: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).usersApproved(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users. Requires \'Siemens Admin\' role.
     * @param {string} [role] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public usersGet(role?: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).usersGet(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending users. Requires \'Siemens Admin\' role.
     * @param {string} [role] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IAMApi
     */
    public usersPending(role?: string, options?: AxiosRequestConfig) {
        return IAMApiFp(this.configuration).usersPending(role, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of all the users (suppliers) that were active yesterday. Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSuppliersYesterday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/activeSuppliersYesterday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all the certificates created yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificatesCreatedYesterday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/certificatesCreatedYesterday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of accounts for Siemens that were approved yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiemensApprovedAccountsYesterday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/siemensApprovedAccountsYesterday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of accounts of Suppliers that were onboarded yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierOnboardedAccountYesterday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/supplierOnboardedAccountYesterday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all the active users (suppliers). Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalActiveSuppliers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/totalActiveSuppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all the created certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCertificatesCreated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/totalCertificatesCreated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of approved accounts for Siemens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSiemensApprovedAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/totalSiemensApprovedAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of onboarded accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplierOnboardedAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/totalSupplierOnboardedAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of all the users (suppliers) that were active yesterday. Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveSuppliersYesterday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveSuppliersYesterday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of all the certificates created yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificatesCreatedYesterday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateCreatedProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificatesCreatedYesterday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of accounts for Siemens that were approved yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiemensApprovedAccountsYesterday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardingProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiemensApprovedAccountsYesterday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of accounts of Suppliers that were onboarded yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplierOnboardedAccountYesterday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardingProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplierOnboardedAccountYesterday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of all the active users (suppliers). Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalActiveSuppliers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalActiveSuppliers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of all the created certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalCertificatesCreated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateCreatedProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalCertificatesCreated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of approved accounts for Siemens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSiemensApprovedAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardingProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalSiemensApprovedAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of onboarded accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSupplierOnboardedAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardingProperties>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalSupplierOnboardedAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of all the users (suppliers) that were active yesterday. Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSuppliersYesterday(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getActiveSuppliersYesterday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of all the certificates created yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificatesCreatedYesterday(options?: any): AxiosPromise<Array<CertificateCreatedProperties>> {
            return localVarFp.getCertificatesCreatedYesterday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of accounts for Siemens that were approved yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiemensApprovedAccountsYesterday(options?: any): AxiosPromise<Array<OnboardingProperties>> {
            return localVarFp.getSiemensApprovedAccountsYesterday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of accounts of Suppliers that were onboarded yesterday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierOnboardedAccountYesterday(options?: any): AxiosPromise<Array<OnboardingProperties>> {
            return localVarFp.getSupplierOnboardedAccountYesterday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of all the active users (suppliers). Requires Siemens Admin role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalActiveSuppliers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getTotalActiveSuppliers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of all the created certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCertificatesCreated(options?: any): AxiosPromise<Array<CertificateCreatedProperties>> {
            return localVarFp.getTotalCertificatesCreated(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of approved accounts for Siemens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSiemensApprovedAccounts(options?: any): AxiosPromise<Array<OnboardingProperties>> {
            return localVarFp.getTotalSiemensApprovedAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of onboarded accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplierOnboardedAccounts(options?: any): AxiosPromise<Array<OnboardingProperties>> {
            return localVarFp.getTotalSupplierOnboardedAccounts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @summary Return list of all the users (suppliers) that were active yesterday. Requires Siemens Admin role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getActiveSuppliersYesterday(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getActiveSuppliersYesterday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of all the certificates created yesterday
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getCertificatesCreatedYesterday(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getCertificatesCreatedYesterday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of accounts for Siemens that were approved yesterday
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getSiemensApprovedAccountsYesterday(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getSiemensApprovedAccountsYesterday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of accounts of Suppliers that were onboarded yesterday
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getSupplierOnboardedAccountYesterday(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getSupplierOnboardedAccountYesterday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of all the active users (suppliers). Requires Siemens Admin role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getTotalActiveSuppliers(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getTotalActiveSuppliers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of all the created certificates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getTotalCertificatesCreated(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getTotalCertificatesCreated(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of approved accounts for Siemens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getTotalSiemensApprovedAccounts(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getTotalSiemensApprovedAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of onboarded accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getTotalSupplierOnboardedAccounts(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getTotalSupplierOnboardedAccounts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuppliersApi - axios parameter creator
 * @export
 */
export const SuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create supplier. Requires Siemens Admin role.
         * @param {CreateSupplierRequest} createSupplierRequest Create supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierCreate: async (createSupplierRequest: CreateSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSupplierRequest' is not null or undefined
            assertParamExists('supplierCreate', 'createSupplierRequest', createSupplierRequest)
            const localVarPath = `/supplier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('supplierDelete', 'id', id)
            const localVarPath = `/supplier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('supplierGet', 'id', id)
            const localVarPath = `/supplier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {string} body Update supplier Request. The id can not be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierUpdate: async (id: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('supplierUpdate', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('supplierUpdate', 'body', body)
            const localVarPath = `/supplier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get suppliers. Requires \'Siemens Admin\' role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppliersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuppliersApi - functional programming interface
 * @export
 */
export const SuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create supplier. Requires Siemens Admin role.
         * @param {CreateSupplierRequest} createSupplierRequest Create supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplierCreate(createSupplierRequest: CreateSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplierCreate(createSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplierDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplierDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplierGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplierGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {string} body Update supplier Request. The id can not be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplierUpdate(id: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplierUpdate(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get suppliers. Requires \'Siemens Admin\' role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suppliersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Supplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suppliersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuppliersApi - factory interface
 * @export
 */
export const SuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuppliersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create supplier. Requires Siemens Admin role.
         * @param {CreateSupplierRequest} createSupplierRequest Create supplier Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierCreate(createSupplierRequest: CreateSupplierRequest, options?: any): AxiosPromise<Supplier> {
            return localVarFp.supplierCreate(createSupplierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.supplierDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierGet(id: string, options?: any): AxiosPromise<Supplier> {
            return localVarFp.supplierGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
         * @param {string} id 
         * @param {string} body Update supplier Request. The id can not be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplierUpdate(id: string, body: string, options?: any): AxiosPromise<Supplier> {
            return localVarFp.supplierUpdate(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get suppliers. Requires \'Siemens Admin\' role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppliersGet(options?: any): AxiosPromise<Array<Supplier>> {
            return localVarFp.suppliersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuppliersApi - object-oriented interface
 * @export
 * @class SuppliersApi
 * @extends {BaseAPI}
 */
export class SuppliersApi extends BaseAPI {
    /**
     * 
     * @summary Create supplier. Requires Siemens Admin role.
     * @param {CreateSupplierRequest} createSupplierRequest Create supplier Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public supplierCreate(createSupplierRequest: CreateSupplierRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).supplierCreate(createSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public supplierDelete(id: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).supplierDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public supplierGet(id: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).supplierGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update supplier. Requires the user part of the supplier or \'Siemens Admin\' role.
     * @param {string} id 
     * @param {string} body Update supplier Request. The id can not be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public supplierUpdate(id: string, body: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).supplierUpdate(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get suppliers. Requires \'Siemens Admin\' role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public suppliersGet(options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).suppliersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


